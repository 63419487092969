import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Modal,
  Divider,
  Descriptions,
  Card,
  Space,
  Tag,
  Image,
  Spin,
  Typography as AntTypography,
  message,
} from "antd";
import { Paper } from "@mui/material";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import AdminMenu from "../../Adm_Menu";
import { FacebookIcon, FacebookShareButton } from "react-share";

const Adm_News_view = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [tags, setTags] = useState([]);
  const [province, setProvince] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch news data
        const newsResponse = await axios.get(`https://checkkonproject-sub.com/api/News_show/${id}`);
        const newsData = newsResponse.data;
        setData(newsData);
        setTags(JSON.parse(newsData.tag) || []); // Parse tags safely

        // Fetch user and province data
        const userResponse = await axios.get(`https://checkkonproject-sub.com/api/User_edit/${newsData.Author}`);
        const userData = userResponse.data;
        setUser(userData);

        const provinceResponse = await axios.get(`https://checkkonproject-sub.com/api/Pvname_request/${userData.province}`);
        setProvince(provinceResponse.data);
      } catch (error) {
        message.error("Error fetching data. Please try again later.");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    fetchData();
  }, [id]);

  const thaiDate = data ? moment(data.created_at).locale("th").format("Do MMMM YYYY") : '';

  const createTypography = (label, text, fontSize = "20px") => (
    <AntTypography.Text style={{ fontSize }}>{label}: {text}</AntTypography.Text>
  );

  const items = [
    { key: "1", label: "ชื่อ", children: user?.username },
    { key: "2", label: "นามสกุล", children: user?.lastName },
    { key: "3", label: "เบอร์โทรศัพท์", children: user?.phone_number },
    { key: "4", label: "ไลน์ไอดี", children: user?.Id_line },
    { key: "5", label: "อีเมล", children: user?.email },
    { key: "6", label: "จังหวัดที่อยู่", children: province?.prov_name },
    { key: "7", label: "เกี่ยวกับผู้เขียน", children: user?.about },
  ].filter(item => item.children); // Filter out null values

  return (
    <AdminMenu>
      <div className="backgroundColor">
        <Paper elevation={0} className="paperContainer" style={{ backgroundColor: "#f0f2f9" }}>
          <Card className="cardsection">
            <div className="cardsectionContent">ข่าวสาร</div>
          </Card>
          <br />
          <Card style={{ borderRadius: 25 }}>
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center", padding: "50px" }}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "16px",
                    marginTop: "16px",
                  }}
                >
                  <Image
                    style={{ borderRadius: 20 }}
                    className="details-image"
                    src={data?.cover_image}
                  />
                </div>
                <div className="cardContent">
                  <Divider />
                  <div className="Contenttitle">{data?.title}</div>
                  <br />
                  {thaiDate}
                  <br />
                  <Divider />
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.details }}
                    style={{ padding: '10px', minHeight: '100px' }}
                  />
                  <br />
                  <div>
                    <Space size={[4, 8]} wrap>
                      {tags.map((tag, index) => (
                        <Tag
                          key={index}
                          style={{ fontSize: "16px", textAlign: "center" }}
                        >
                          #{tag}
                        </Tag>
                      ))}
                    </Space>
                  </div>
                  <br />
                  <FacebookShareButton
                    url={`https://checkkonproject.com/News_Menu/News_view/${data?.id}`}
                    title={data?.title}
                    image={data?.cover_image}
                  >
                    <FacebookIcon size={50} round /> แชร์ไปยัง Facebook
                  </FacebookShareButton>
                  <p onClick={showModal}>
                    <Avatar size={64} icon={<UserOutlined />} style={{ backgroundColor: '#7BBD8F' }}>
                      {user?.username?.[0] ?? ''}
                    </Avatar>{" "}
                    ผู้เขียน <span>{user?.username}</span>
                  </p>
                  <Modal
                    title="โปรไฟล์ผู้เขียน"
                    open={isModalOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <Descriptions layout="vertical" bordered>
                      {items.map(item => (
                        <Descriptions.Item key={item.key} label={item.label}>
                          {item.children}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  </Modal>
                </div>
              </>
            )}
          </Card>
        </Paper>
      </div>
    </AdminMenu>
  );
};

export default Adm_News_view;
